@import '../public/assets/font/borna/stylesheet.css';
@import "variables";

@font-face {
  font-family: "Billion Dreams";
  src: url("/assets/font/BillionDreams.ttf") format("truetype");
}

* {
  font-family: $ff-helvetica;
}

body {
  margin: 0;
  background-image: url('/assets/images/bg-mv.webp');
  background-repeat: repeat;
  &::-webkit-scrollbar {
    display: none;
  }
}

.mat-typography p,
p,
a,
label,
span {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  scrollbar-width: none;
}

img {
  height: auto;
}

.i18n-bold {
  font-weight: $fw-bold;
}

.billion-orange {
  font-family: $ff-billions;
  color: $orange;
}

.billion-green {
  font-family: $ff-billions;
  color: $green;
}

.btn {
  cursor: pointer;
  border: none;
  outline: none;
  &:active {
    scale: 0.95;
  }
}

@include tablet {
  body {
    background-image: url('/assets/images/bg.webp');
  }
}
