$ff-helvetica: "Helvetica";
$ff-billions: "Billion Dreams";

//Font weight
$fw-normal: 400;
$fw-medium: 500;
$fw-semi: 600;
$fw-bold: 700;

//Font size
$fs-6: 0.375rem;
$fs-7: 0.438rem;
$fs-8: 0.5rem;
$fs-9: 0.563rem;
$fs-10: 0.625rem;
$fs-11: 0.688rem;
$fs-12: 0.75rem;
$fs-13: 0.813rem;
$fs-14: 0.875rem;
$fs-15: 0.938rem;
$fs-16: 1rem;
$fs-17: 1.063rem;
$fs-18: 1.125rem;
$fs-19: 1.188rem;
$fs-20: 1.25rem;
$fs-21: 1.313rem;
$fs-22: 1.375rem;
$fs-23: 1.438rem;
$fs-24: 1.5rem;
$fs-25: 1.563rem;
$fs-26: 1.625rem;
$fs-27: 1.688rem;
$fs-28: 1.75rem;
$fs-29: 1.813rem;
$fs-30: 1.875rem;
$fs-32: 2rem;
$fs-35: 2.188rem;
$fs-36: 2.25rem;
$fs-37: 2.313rem;
$fs-38: 2.375rem;
$fs-40: 2.5rem;
$fs-42: 2.625rem;
$fs-44: 2.75rem;
$fs-45: 2.813rem;
$fs-46: 2.875rem;
$fs-48: 3rem;
$fs-50: 3.125rem;
$fs-52: 3.25rem;
$fs-55: 3.438rem;
$fs-58: 3.625rem;
$fs-60: 3.75rem;
$fs-64: 4rem;
$fs-65: 4.063rem;
$fs-70: 4.375rem;
$fs-75: 4.688rem;
$fs-80: 5rem;
$fs-85: 5.313rem;
$fs-95: 5.938rem;
$fs-100: 6.25rem;
$fs-130: 8.125rem;
$fs-140: 8.75rem;

//Color
$orange: #F7A600;
$violet: #2B2140;
$gray: #F7F7F7;
$bg-gray: #EAEAEA;
$green: #00A183;
$yellow: #FCEA00;
$gold: #D39008;
$salmon: #FFC7C2;
$white: #fff;
$black: #000;
$lightgray: #ececec;

@mixin tablet {
  @media (min-width: 834px) {
    @content;
  }
}

@mixin landscape {
  @media (min-width: 1126px) {
    @content;
  }
}

@mixin pc {
  @media (min-width: 1900px) {
    @content;
  }
}

//Variables
@mixin flex($direction: row, $content: unset, $align: unset) {
  display: flex;
  flex-direction: $direction;
  justify-content: $content;
  align-items: $align;
}

@mixin cookiesScroll {
  &::-webkit-scrollbar {
    background-color: $violet;
    border: 2px solid $white;
    border-radius: 10px;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $orange;
  }
}

@mixin animatedButton {
  position: relative;
  overflow: hidden;
  padding: 0;
  transition: color 0.6s ease-in-out;
  &::after {
    content: '';
    position: absolute;
    background-color: $orange;
    width: 100%;
    height: 100px;
    top: 100%;
    border-radius: 50% 50% 0 0;
    left: 0;
    transition: top 0.3s ease-in;
  }

  span {
    display: inline-block;
    position: relative;
    transition: transform 0.3s ease;
    will-change: transform;
    z-index: 2;
  }

  &:hover {
    color: $violet;
    &::after {
      top: -35px;
    }
  }

  &:hover span {
    animation: move 1s linear forwards 1s;
  }

  @include pc {
    &::after {
      height: 7vw;
    }

    &:hover {
      &::after {
        top: -3vw;
      }
    }
  }

  @keyframes move {
    30%,
    36% {
      transform: translateY(-8px) translateZ(0) rotate(-13deg);
    }

    50% {
      transform: translateY(4px) translateZ(0) rotate(6deg);
    }

    70% {
      transform: translateY(-4px) translateZ(0) rotate(-3deg);
    }
  }
}

@mixin animatedLeftButton {
  position: relative;
  overflow: hidden;
  padding: 0;
  transition: color 0.6s ease-in-out;
  &::after {
    content: '';
    position: absolute;
    background-color: $orange;
    width: 120%;
    height: 100px;
    left: 100%;
    border-radius: 50% 0 0 50%;
    top: -30px;
    transition: left 0.3s ease-in;
  }

  span {
    display: inline-block;
    position: relative;
    transition: transform 0.3s ease;
    will-change: transform;
    z-index: 2;
  }

  &:hover {
    color: $violet;
    &::after {
      left: -5%;
    }
  }

  &:hover span {
    animation: moveLeft 1s linear forwards 1s;
  }

  @include pc {
    &::after {
      height: 7vw;
      top: -1.5625vw;
    }

    &:hover {
      &::after {
        left: -1vw;
      }
    }
  }

  @keyframes moveLeft {
    30%,
    36% {
      transform: translateX(-8px) translateZ(0px) rotate(-13deg);
    }

    50% {
      transform: translateX(4px) translateZ(0px) rotate(6deg);
    }

    70% {
      transform: translateX(-4px) translateZ(0px) rotate(-3deg);
    }
  }
}

@mixin animatedRightButton {
  position: relative;
  overflow: hidden;
  padding: 0;
  transition: color 0.6s ease-in-out;
  &::after {
    content: '';
    position: absolute;
    background-color: $orange;
    width: 120%;
    height: 100px;
    right: 100%;
    border-radius: 0 50% 50% 0;
    top: -30px;
    transition: right 0.3s ease-in;
  }

  span {
    display: inline-block;
    position: relative;
    transition: transform 0.3s ease;
    will-change: transform;
    z-index: 2;
  }

  &:hover {
    color: $violet;
    &::after {
      right: -5%;
    }
  }

  &:hover span {
    animation: moveRight 1s linear forwards 1s;
  }

  @include pc {
    &::after {
      height: 7vw;
      top: -1.5625vw;
    }

    &:hover {
      &::after {
        right: -1vw;
      }
    }
  }

  @keyframes moveRight {
    30%,
    36% {
      transform: translateX(8px) translateZ(0px) rotate(13deg);
    }

    50% {
      transform: translateX(-4px) translateZ(0px) rotate(-6deg);
    }

    70% {
      transform: translateX(4px) translateZ(0px) rotate(3deg);
    }
  }
}
